'use client';

import { Collapse, Divider } from 'antd';
import Image from 'next/image';
import { useState } from 'react';
import styled from 'styled-components';
import Circle from '../../../../../public/images/circel.png';
import VectorTesti from '../../../../../public/images/VectorTesti.png';
import yellowStar from '../../../../../public/images/yellowStar.png';
import LocationCarousel from '../../Carousel/LocationCarousel';

const CustomCollapse = styled(Collapse)`
  .ant-collapse-expand-icon > span svg {
    fill: #d29a2a !important;
  }
`;

const FAQSection = () => {
  const [active, setActive] = useState<number>(11);

  const items = [
    {
      label: 'What is the minimum age requirement for children?',
      content:
        'For the service, you will need to change into our spa clothes so we can get to your neck and shoulders. We recommend wearing a top and pants rather than a romper or jumpsuit because they are harder to change.',
    },
    {
      label: 'Can textured hair be treated with a Head Spa?',
      content: `Yes, our specialists can work with all hair types, including textured and bald hair. However, we do require unobstructed access to the scalp for our services.
To ensure the best results, we kindly ask customers with extensions or dreadlocks to consider removing them before your Head Spa treatment. Please note that we cannot be held responsible for any damage if you choose to proceed with the service while keeping these in.
For dreadlocks, braids, and curly or coily hair types, we offer towel drying and brush out, or an optional simple blow dry.`,
    },
    {
      label: 'How should I dress for the spa?',
      content:
        'Yes, we can accommodate up to 16 guests at once, but making an appointment in advance is strongly recommended.',
    },
    {
      label: 'Is it safe for expecting mothers to try the Head Spa?',
      content: `Yes, it is safe for pregnant clients to enjoy our Head Spa! We use all-natural and organic ingredients. However, if you have concerns about specific oils, we recommend consulting your doctor beforehand and informing us in advance.
Please note that guests will lie flat on their backs during the service. If this position is uncomfortable for you, we suggest waiting until after delivery to book your appointment.`,
    },
    {
      label: 'Will the Head Spa affect my keratin and color?',
      content:
        'We provide spa attire for your convenience, allowing us to easily access your neck and shoulders during the service. We recommend wearing a top and pants instead of a romper or jumpsuit, as these can be more challenging to change.',
    },
    {
      label: 'Can I record myself during the service?',
      content: `Yes, you can record yourself for a few minutes with prior permission! However, we kindly ask that you set your phone to silent mode and refrain from talking, as there may be other clients in the spa room. If possible, please place your phone to the side so you can fully enjoy the experience!`,
    },
    {
      label: 'Can I enjoy the Head Spa with friends or family?',
      content:
        'Absolutely! We can accommodate up to 19 guests at the same time depends on location. However, we strongly recommend making an appointment in advance to ensure availability.',
    },
    {
      label: 'Is there a private room available since I wear a scarf?',
      content:
        'Yes, we can accommodate your needs depends on location! If you prefer privacy, please let us know in advance, and we will arrange for a private room for your comfort during the service.',
    },
    {
      label: 'Will the Head Spa affect my keratin treatment or hair color?',
      content:
        'You can safely use our products on any treated hair! However, please note that color or keratin may fade during our detoxification process if you have had recent chemical treatments. We recommend waiting at least 7 days after any hair treatment before scheduling your Head Spa.',
    },
    {
      label: 'What is the minimum age requirement for children?',
      content:
        'Children aged 4 and older are welcome to enjoy our services when accompanied by an adult or guardian, provided they can remain quiet in the room. Please feel free to contact us in advance to discuss any specific accommodations.',
    },
  ];

  return (
    <section className='relative z-10 flex h-full flex-col items-center bg-zelene-beige pb-[90px] pt-6 sm:pt-0'>
      <Image
        src={Circle}
        alt='red star'
        width={120}
        height={120}
        className='absolute left-12 top-36 h-[120px] w-[120px] object-contain lg:left-24 lg:top-24'
      />

      <div className='absolute right-0 top-0 z-20 h-1/4 w-[min(676px,70%)]'>
        <Image alt='vector' src={VectorTesti} fill sizes='40vw' className='z-20 object-contain' />
      </div>

      <div className='flex w-[90%] flex-col items-center justify-center *:z-30 *:text-zelene-blue'>
        <div className='flex w-full items-center justify-between'>
          <Image src={yellowStar} alt='yellowStar' width={60} height={60} className='!size-[60px] object-contain' />
          <span className='font-hneu text-xl capitalize leading-5 sm:text-2xl sm:leading-[28px]'>
            Top Client Inquiries
          </span>
          <Image src={yellowStar} alt='yellowStar' width={60} height={60} className='!size-[60px] object-contain' />
        </div>
        <div className='h-2 w-[48px] border-b border-zelene-blue pt-8'></div>

        <span className='pt-2.5 text-center font-[didot] text-[clamp(2.5rem,0.2941rem_+_3.0216vw,4rem)] font-bold capitalize leading-tight md:leading-[80px]'>
          Frequency Asked Questions
        </span>
      </div>

      <div className='z-30 mx-auto flex w-[min(1512px,80%)] flex-col gap-x-12 gap-y-2 pt-8 *:flex-1 lg:grid lg:grid-cols-12 lg:flex-row lg:*:col-span-6'>
        {items.map((item, index: number) => (
          <div key={index}>
            <CustomCollapse
              onChange={vals => setActive(Number(vals))}
              activeKey={active}
              accordion
              ghost
              expandIconPosition='end'
              items={[
                {
                  key: index,
                  label: (
                    <div className='flex items-center gap-1'>
                      <Image
                        src={yellowStar}
                        alt='yellowStar'
                        width={20}
                        height={20}
                        className='!size-[20px] object-contain'
                      />
                      <span className='font-hneu text-lg font-medium text-zelene-blue sm:text-xl'>{item.label}</span>
                    </div>
                  ),
                  children: <p className='font-hneu text-sm text-zelene-blue sm:text-base'>{item.content}</p>,
                },
              ]}
            />
            <Divider className='!my-0 border-[1.5px] !border-zelene-yellow' />
          </div>
        ))}
      </div>

      <LocationCarousel />
    </section>
  );
};

export default FAQSection;
